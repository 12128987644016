import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { requestPermission, showNotification } from "./notification.js";
import { Box, Grid } from "@mui/material";

const Camera = (data) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [permissionCam, setPermissionCam] = useState(false);
  const [listImagesLocalStorage, setListImagesLocalStorage] = useState([]);

  function requestCameraPermission() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function (stream) {
        const video = document.querySelector("video");
        video.srcObject = stream;
      })
      .catch(function (err) {
        console.error("Error accessing the camera: ", err);
      });
  }
  useEffect(() => {
    showImage();
  }, []);

  const showImage = () => {
    const img = [];
    const listImage = Object.entries(localStorage);
    listImage.map((imageObject) => {
      const image = JSON.parse(imageObject[1]);
      img.push(image.image);
    });
    setListImagesLocalStorage(img);
  };

  // useEffect(()=>{
  //   console.log(listImagesLocalStorage);
  // },[listImagesLocalStorage])

  useEffect(() => {
    navigator.permissions.query({ name: "camera" }).then((permission) => {
      if (permission.state === "granted") {
        setPermissionCam(true);
      } else {
        setPermissionCam(false);
        requestCameraPermission();
      }
    });
  }, [data]);

  const capture = () => {
    requestPermission();
    if (data.isOnline) {
      showNotification("Photo capturé avec Succès !");
    }
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    var key = localStorage.length + 1;
    var value = {
      image: imageSrc,
      synchronisation: data.isOnline,
    };
    var valueJSON = JSON.stringify(value);
    localStorage.setItem("photo_" + key, valueJSON);
    showImage();
  };

  return (
    <Box>
      {permissionCam ? (
        <Box>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            onClick={capture}
            style={{
              maxWidth: "100%",
              height: "auto"
            }}
          />
          <h2>Galerie :</h2>
          <Grid container spacing={2}>
            {listImagesLocalStorage.map((image, index) => (
              <Grid item xs={4} key={index}>
                <img
                  style={{ marginTop: 10, width: "70%", height: "100%" }}
                  src={image}
                  alt="imageLocalStorage"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box>
          <h3>La permission pour accèder à la Caméra est réfusée</h3>
        </Box>
      )}
    </Box>
  );
};

export default Camera;
