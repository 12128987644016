export const requestPermission = async () => {
  if (!("Notification" in window)) {
    throw new Error("Notification not supported");
  }
  const permission = await window.Notification.requestPermission();
  if (permission !== "granted") {
    return new window.Notification("Permission not granted for Notification");
  }
};


export const showNotification = async (title, options) => {
  await requestPermission();

  const registration = await navigator.serviceWorker.getRegistration();

  if (!("Notification" in window)) {
    throw new Error("Notification not supported");
  }
  if (window.Notification.permission !== "granted") {
    return new window.Notification("Permission non accordé pour les notifications");
  }
  if (registration && "showNotification" in registration) {
    registration.showNotification(title, options);
  } else {
    new Notification(title, options);
  }

  if ("vibrate" in navigator) {
    navigator.vibrate([200, 100, 200]);
  }
};
