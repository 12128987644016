import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import Camera from "./Components/Camera.js";
import Maps from "./Components/Map.js";
import WifiIcon from "@mui/icons-material/Wifi.js";
import WifiOffIcon from "@mui/icons-material/WifiOff.js";
import Battery from "./Components/Battery.js";
import PhoneCall from "./Components/PhoneCall.js";
import {
  requestPermission,
  showNotification,
} from "./Components/notification.js";

export const isIOS = () => {
  const browserInfo = navigator.userAgent.toLowerCase();

  if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
    return true;
  }
  if (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform)
  ) {
    return true;
  }
  return false;
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabsComponents = () => {
  const [value, setValue] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [location, setLocation] = useState({});
  const [isIOSCheck, setIsIOSCheck] = useState(false);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    if (navigator.onLine) {
      requestPermission();
      const listImage = Object.values(localStorage).map((item) =>
        JSON.parse(item)
      );
      let countImageSynchro = 0;
      listImage.forEach((image, index) => {
        if (image.synchronisation === false) {
          countImageSynchro++;
          listImage[index] = { ...image, synchronisation: true };
          localStorage.setItem(
            Object.keys(localStorage)[index],
            JSON.stringify(listImage[index])
          );
        }
      });
      showNotification(
        "Synchronisation des images effectuées. \n Nombre d'image sycnhronisé : " +
          countImageSynchro
      );
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [isOnline]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLocation(position);
    });
    const isvarIOS = isIOS();
    setIsIOSCheck(isvarIOS);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#cee8ff",
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Photo" {...a11yProps(0)} />
          <Tab label="Map" {...a11yProps(1)} />
          <Tab label="Appel" {...a11yProps(2)} />
        </Tabs>

        <Typography
          variant="body1"
          sx={{
            marginLeft: "auto",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              border: "2px #1976d2 solid ",
              padding: "2%",
              borderRadius: "10px",
              display: "flex",
            }}
          >
            {!isIOSCheck ? <Battery /> : <p>IOS</p>}
            {isOnline ? (
              <WifiIcon color="primary" sx={{ margin: "10px" }} />
            ) : (
              <WifiOffIcon color="error" sx={{ margin: "10px" }} />
            )}
          </Box>
        </Typography>
      </Box>
      <TabPanel value={value} index={0}>
        <Camera isOnline={isOnline} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Maps location={location} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PhoneCall phoneNumber={"0660860650"} />
      </TabPanel>
    </Box>
  );
};

export default TabsComponents;
