import { Phone } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

const PhoneCall = (phoneNumber) => {

  const handleCall = () => {
    window.location.href = `tel:0660860650`;
  };

  return (
    <Box>
      <Button variant="contained" onClick={handleCall}><Phone/> {phoneNumber.phoneNumber}</Button>
    </Box>
  );
};

export default PhoneCall;
