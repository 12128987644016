import { Box } from "@mui/material";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import BatteryAlertIcon from "@mui/icons-material/BatteryAlert";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import BatteryCharging50Icon from "@mui/icons-material/BatteryCharging50";
import BatteryCharging20Icon from "@mui/icons-material/BatteryCharging20";
import Battery20Icon from "@mui/icons-material/Battery20";
import { useEffect, useState } from "react";
import { isIOS } from "../Tabs";

const Battery = () => {
  const [batteryIcon, setBatteryIcon] = useState(null);
  const [batteryLevel, setBatteryLevel] = useState(null);
  const [isCharging, setIsCharging] = useState(false);

  useEffect(() => {
    const check = isIOS();
    if(!check){
      navigator.getBattery().then((battery) => {
        setBatteryLevel(Math.round(battery.level * 100));
        setIsCharging(battery.charging);
      });
  
      if (isCharging) {
        if (batteryLevel === 100) {
          setBatteryIcon(<BatteryChargingFullIcon />);
        } else if (batteryLevel >= 50) {
          setBatteryIcon(<BatteryCharging50Icon />);
        } else if (batteryLevel >= 20) {
          setBatteryIcon(<BatteryCharging20Icon />);
        } else {
          setBatteryIcon(<Battery20Icon />);
        }
      } else {
        if (batteryLevel === 100) {
          setBatteryIcon(<BatteryFullIcon />);
        } else if (batteryLevel >= 20) {
          setBatteryIcon(<BatteryAlertIcon />);
        } else {
          setBatteryIcon(<Battery20Icon />);
        }
    }

    }
  }, [isCharging, batteryLevel]);

  return (
    <Box sx={{ display: "flex", margin: "10px" }}>
      {batteryLevel !== null ? batteryLevel + "%" : "N/A"} {batteryIcon}
    </Box>
  );
};

export default Battery;
