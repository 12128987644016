import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Importer l'icône par défaut de Leaflet
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Configuration de l'icône par défaut de Leaflet
let DefaultIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconAnchor: [12, 41] // Pour aligner l'icône correctement sur la carte
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map = ({ location }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    if (location && location.coords) {
      setLatitude(location.coords.latitude);
      setLongitude(location.coords.longitude);
    }
  }, [location]);

  return (
    <Box>
      {latitude !== null && longitude !== null ? (
        <Box>
          <h2>Géolocalisation :</h2>
          <p>Longitude : {longitude}</p>
          <p>Latitude : {latitude}</p>
          <MapContainer
            center={[latitude, longitude]}
            zoom={13}
            scrollWheelZoom
            style={{ height: "600px", width: "100%" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[latitude, longitude]}>
              <Popup>
                Vous êtes ici 👽
              </Popup>
            </Marker>
          </MapContainer>
        </Box>
      ) : (
        <h2>Localisation Indisponible</h2>
      )}
    </Box>
  );
};

export default Map;
